<template>
  <div>
    <div
      class="page-title d-flex justify-content-between align-items-center
        border-bottom pb-2 pt-3 pt-mb-0 d-none d-md-block">
      <div class="text-title-active text-poppins fw-600 fs-16 mx-3 mx-md-0">
        Pemberitahuan
      </div>
    </div>

    <pre v-if="$route.query.debug">{{ fcmToken }}</pre>

    <div v-if="(loading.notification && !notifications.length) || loading.notificationDetail" class="item-content">
      <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
          <div class="not-empty d-flex flex-column align-items-center pt-3">
              <div class="bd-highlight mb-4">
                <img src="@/assets/icon/empty.png" alt="">
              </div>
              <div class="bd-highlight">
                <div class="fm-poppins text-title-active fw-600 fs-32">Loading..</div>
              </div>
          </div>
      </div>
    </div>

    <div v-else-if="true || !loading.notification && notifications.length > 0" class="item-content px-0">
      <div v-for="notification in notifications" :key="notification.status_read" class="item-order d-block">
        <div class="card card-info">
          <div
            @click="getDetails(notification)"
            role="button"
            class="card-header d-flex justify-content-between align-items-center gap-3"
            :class="{ 'unread':notification.status_read == 0 }"
          >
            <div class="d-flex justify-content-left align-items-center gap-3">
              <div>
                <img src="@/assets/services/blood-test.svg" alt="Ikon" class="icon">
              </div>
              <div class="d-flex flex-column gap-1">
                <span class="fw-bold fs-14 lh-24 color-neutral-900">
                  {{ notification.title }}
                </span>
                <span class="d-block d-md-none fw-400 fs-12 lh-14 color-neutral-600">
                  {{ notification.body }}
                </span>
              </div>
              <div class="d-none d-md-block">
                <span class="fm-nunito fw-600 fs-14">
                  {{ notification.body }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center gap-1">
              <div class="d-none d-md-block">
                <span
                  class="badge fm-nunito fw-600 fs-14 py-2 px-3 ms-2"
                  :class="{
                    'bg-success-disable text-success':notification.status_read == 1,
                    'bg-warning-disable text-warning':notification.status_read == 0,
                  }"
                >
                  {{ (notification.status_read == 1) ? 'Sudah Dibaca':'Belum Dibaca' }}
                </span>
              </div>
              <div class="icon-wrapper">
                <img src="@/assets/icon/arrow-right-outline.svg" alt="Ikon" class="icon">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="item-content">
      <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
        <div class="not-empty d-flex flex-column align-items-center pt-3">
          <img
            src="@/assets/icon/notif-empty.svg"
            alt=""
            class="mb-4"
          >
          <div class="fw-700 fs-18 lh-28 color-neutral-900">Tidak ada pemberitahuan</div>
          <p class="fw-400 fs-14 lh-24 color-neutral-600 mb-0">Saat ini tidak ada pemberitahuan</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { nextTick, onMounted } from 'vue'
import { title } from '@/core/page'
import {
  loading, notifications, getNotificationDetails,
  getNotifications, shouldAskNotifPermission
} from '@/core/notifications'
import { user } from '@/core/auth'
import { fcmToken } from '@/core/firebase-messaging'

export default {
  setup () {
    onMounted(() => {
      nextTick(() => {
        title.value = 'Notifikasi'
      })
    })

    const router = useRouter()
    const getDetails = async notification => {
      try {
        // Ambil data detailnya
        getNotificationDetails({
          id_not: notification.id_not
        })

        // refresh data notif
        getNotifications()

        // Next ambil data lengkapnya
        // If payment
        if (['payment', 'hasil'].includes(notification.channel_id)) {
          router.push({
            name: 'ProfilOrderDetail',
            params: {
              id_order: notification.id_data
            }
          })
        }
      } catch (error) {
        console.error('Error detail notifikasi = ', error)
      }
    }

    return {
      notifications,
      user,
      loading,
      getDetails,
      shouldAskNotifPermission,
      fcmToken
    }
  }
}
</script>

<style lang='scss' scoped>
.card-header {
  background-color: unset;
  border-bottom: #EFF0F6;
  padding: 12px 18px;
}

.card-header.unread {
  background-color: #F7FAFC;
}

.card {
  border: none;

  .icon-wrapper img {
    width: 18px;
    height: 18px;
  }
}

.badge {
  border-radius: 8px;
}

.icon {
  width: 48px;
  height: 48px;
}
</style>
